#dialog {
  .modal.open {
    display: block;

    .question {
      font-size: 26px;
      font-weight: bold;
      line-height: 1.19;
    }

    .modal-body {
      padding: 0;
    }
  }
}
