@import "default-input";
@import "calendar";
@import "datetime";
@import "datetime-range";
@import "react-select";
@import "tab-button";
@import "checkbox";
@import "filters";
@import "wysiwyg";
@import "validated-field";
@import "dropdown";

.input-field-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  .input-icon {
    z-index: 1;
    position: absolute;
    font-size: 14px;
    left: 15px;
    color: #cbd9f5;
  }

  .react-select {
    .react-select__value-container {
      padding-left: 37px;
    }
  }

  .default-input {
    padding-left: 40px;
  }
}
