html {
    scroll-behavior: smooth;
}

html, body {
    height: 100% !important;
}

body {
    margin: 0;
}

#root {
    height: 100%;
    width: 100vw;
}
