.course-editor {


  .drag-handle {
    cursor: pointer;
  }

  .drop-might-happen {
    border-left: 10px solid #28a745 !important;
  }
}
