.browse-files-modal {
  .table .table-header {
    border-top: 0;
  }

  .file-filters {
    grid-template-columns: 20px 1fr 40px;
  }

  .pagination {
    li.active {
      font-weight: bold;
    }
  }
}
