.feedback-table {

  .table-header {
    .table-row {
      grid-template-columns: 25px 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .row-title {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 25px 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .row-content {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 25px 1fr;

    border-bottom: 1px solid black;
    margin-bottom: 20px;
  }
}
