.course-publishing-log {
  .log-wrapper {
    max-height: 500px;
    overflow: scroll;
    display: flex;
    flex-direction: column-reverse;

    .log-entry {
      display: grid;
      grid-template-columns: 200px 1fr;
    }
  }
}
