
.attachment-select {
  display: flex;
  flex-direction: row;
  align-items: center;

  > .list-group {
    width: 100%;
    margin-right: 20px;
    margin-bottom: 0 !important;
  }
}

#modal {
  .files-table {
    max-height: 500px;
    overflow: scroll;
  }
}
