.upsell-options-field {
  .table-row {
    grid-template-columns: 100px 1fr 1fr 1fr 100px;

    .table-column {
      align-items: flex-start;
    }
  }

  .table-row:not(:first-child) {
    border-top: 1px solid black;
  }
}
