#modal {
  .modal {

    &.show {
      display: block;
    }

    .modal-body {
      padding: 0;
    }
  }
}
