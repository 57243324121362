.pagination {
  font-size: 14px;
  line-height: 1.21;
  flex-wrap: wrap;
  li {
    padding: 10px;
    cursor: pointer;

    svg {
      font-size: 10px;
      line-height: 1;
    }
  }
}
