#modal {
  > .modal {
    z-index: 9999;
  }
}

#dialog {
  > .modal {
    z-index: 99999;
  }
}
