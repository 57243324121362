.text-filter {
  position: relative;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #e9eef8;

    &:focus {
      border: solid 1px #cbd9f5;
      background-color: #ffffff;
    }
  }

  svg {
    position: absolute;
    right: 15px;
    font-size: 17px;
    color: #cbd9f5;
  }
}
