@import "~bootstrap/scss/bootstrap";
@import '~filepond/dist/filepond.min.css';
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

@import "controls/index";
@import "components/cross";
@import "components/overview-header";
@import "components/modal";
@import "components/attachment-select";

@import "~primeflex/primeflex.css";

.react-select__value-container {
  overflow: hidden !important;
}

.message-display {
  background-color: white;
  box-shadow: 1px 1px black;
}

.line-through {
  text-decoration: line-through;
}
