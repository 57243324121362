.files-table {
  .table-row {
    grid-template-columns: 25px 1fr 1fr 1fr 1fr;
  }

  .name-field {
    text-overflow: ellipsis;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
  }
}
